// // core version + navigation, pagination modules:
// import Swiper, { Navigation, Pagination, Autoplay } from 'swiper';
// // import Swiper and modules styles
// import 'swiper/css';
// import 'swiper/css/navigation';
// import 'swiper/css/pagination';
  
import Swiper from 'swiper/bundle';

// import styles bundle
import 'swiper/css/bundle';




const swiper = new Swiper('.swiper', {
    speed: 3000,
    autoplay: {
        delay: 2000,
        disableOnInteraction: false
    },

    loop: false,
    
    slidesPerView: 'auto',
    centeredSlides: true,

    a11y: true,
    keyboardControl: true,
    //grabCursor: true,

    pagination: {
        el: ".swiper-pagination",
        clickable: true
    },
    navigation: {
        nextEl: "#js-next2",
        prevEl: "#js-prev1"
    }

});

// var swiper2 = new Swiper('.swiper2', {
//     // Disable preloading of all images
//     loop: true,
//       // Enable lazy loading
//       lazy: true,
//       lazy: {
//           loadPrevNext: true,
//           loadOnTransitionStart: true
//       },
//     effect: 'fade',
//     speed: 2000,
//     fadeEffect: {
//       crossFade: true,
//     },
//     autoplay: {
//        delay: 7000,
//     },
//   });

// Initialize Swiper without lazy loading
var swiper2 = new Swiper('.swiper2', {
    loop: true,
    effect: 'fade',
    speed: 2000,
    fadeEffect: {
        crossFade: true,
    },
    autoplay: {
        delay: 7000,
    },
    lazy: {
        loadPrevNext: true,
        loadOnTransitionStart: true
    },
});

// Lazy load images using Intersection Observer
// const lazyLoadSwiperImages = () => {
//     const swiperImages = document.querySelectorAll('.swiper2 .swiper-lazy');
//     const observer = new IntersectionObserver((entries, observer) => {
//         entries.forEach(entry => {
//             if (entry.isIntersecting) {
//                 const lazyImage = entry.target;
//                 lazyImage.src = lazyImage.dataset.srcset;
//                 lazyImage.classList.remove('swiper-lazy');
//                 observer.unobserve(lazyImage);
//             }
//         });
//     });
//     swiperImages.forEach(image => {
//         observer.observe(image);
//     });
// };

// lazyLoadSwiperImages();


const mySwiper = document.querySelector('.swiper').swiper;
let isSliderActive = true;

document.getElementById("slider-control").addEventListener("click", function() {
    //this.style.backgroundColor = "red";
    if (isSliderActive) {
        mySwiper.autoplay.stop();
        document.getElementById("slider-control").style.backgroundImage = "url('../../img/pause-button.png')";
        isSliderActive = false;
        //this.innerHTML = 'PLAY';
        console.log('slider stopped');
    } else {
        mySwiper.autoplay.start();
        isSliderActive = true;
        document.getElementById("slider-control").style.backgroundImage = "url('../../img/play-button.png')";
        //this.innerHTML = 'PAUSE';
        console.log('slider activated');
    }
});